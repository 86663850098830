.disclaimer-box {
  padding: 15px;
  max-width: 400px;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  max-height: calc(100% - 60px);
}

.disclaimer-title {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.disclaimer-message {
  color: black;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: start;
  overflow-y: auto;
  display: flex;
  height: calc(600px - 200px);
}

.disclaimer-btn {
  background-color: #1a2c3e;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  bottom: 0;
}

.disclaimer-btn:hover {
  background-color: #ffffff;
  color: #1a2c3e;
}
