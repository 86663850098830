.zchat.quickreplybar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zchat.quickreplybar-arrows {
  width: fit-content;
  margin: 10px;
  color: rgb(0, 95, 115);
  cursor: pointer;
}

.zchat.msg-qrlist {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
}

.zchat.quickreplybar-btn {
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(0, 95, 115) 0%, rgb(0, 95, 115) 75.75%, rgb(0, 95, 115) 100%);
  padding: 1px;
  border-radius: 5px;
  margin: 2px;
}

.zchat.quickreplybar-btn-text {
  width: fit-content;
  padding: 5px 10px;
  color: rgb(0, 95, 115);
  font-weight: bold;
  background: white;
  user-select: none;
  border-radius: 5px;
  white-space: nowrap;
}

.zchat.quickreplybar-btn-text:hover {
  background: linear-gradient(90deg, rgb(0, 95, 115) 0%, rgb(0, 95, 115) 75.75%, rgb(0, 95, 115) 100%);
  color: white;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .zchat.msg-qrlist {
    -ms-overflow-style: none;
    overflow: auto;
  }
}
