.linePlot {
  height: 80%;
  width: 100%;
  box-shadow: 5px 5px 13px #ededed, 5px 5px 13px #ffffff;
  border-radius: 20px;

  transition: 0.1s;
  &:hover {
    transform: scale(1.01);
  }

  .linePlotContainer {
    height: 90%;
    width: 95%;

    display: flex;
  }
}
