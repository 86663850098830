.menubtn:hover {
  color: black;
  background: white;
}

.menubtn.inPage {
  background: white;
}

.menubtn {
  color: rgba(242, 132, 62, 1);
}
