.LeaderboardViewContainer,
.LBVS {
  display: block;
  width: 99%;
  margin: 0 auto;
}

.ArenaLogBox {
  display: flex;
  margin: auto;
}

.ArenaLogTableHead {
  .MuiTableCell-root {
    font-size: 1.1em;
  }
}

.ArenaLogTableRow:hover {
  background-color: rgba(125, 125, 125, 0.2);
}

.ArenaLogTableRow,
.ArenaLogTableHead {
  border-bottom: 1.5px solid rgba(125, 125, 125, 0.2);
}
