.Distribution {
  align-items: center;
  text-align: left;
  height: 100%;
  width: 80%;
}

.container {
  height: 100% !important;
  flex: 1 1 0px !important;
  width: 0 !important;
}
