.zchat.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* height: 50px; */
  padding-top: 10px;
}

.zchat.inputbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  min-width: 50px;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(200, 200, 200, 1);
}

.zchat.inputfield {
  width: 100%;
  margin: 5px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zchat.inputfield textarea {
  font-size: 16px;
  width: 100%;
  resize: none;
  border: none;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 58px;
}

.zchat.inputfield textarea:focus {
  outline: none;
}

.zchat.inputsend {
  margin: 5px;
  padding: 5px;
  text-align: center;
  width: 35px;
  height: 30px;
  border-radius: 20px;
  color: rgb(0, 95, 115, 1);
}

.zchat.inputsend:hover {
  color: rgb(232, 232, 228, 1);
  background: rgba(0, 95, 115);
}

.zchat.inputsend img {
  width: 100%;
  height: 100%;
}

.zchat.inputfield.en textarea {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .zchat.inputfield textarea {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: auto;
  }

  .zchat.inputbox {
    height: 35px;
  }
}
