.menu {
  left: -100px;
  transition: left 1s;
  box-shadow: 5px 5px 5px #ededed, 5px 5px 5px #ffffff;
}

.menu:hover {
  left: 0px;
  transition-property: left;
  transition-duration: 1s;
}

.menu:not(:hover) {
  left: -100px;
  transition-property: left;
  transition-duration: 1s;
  transition-delay: 0.2s;
}

.menu.mini {
  left: -100px;
}
